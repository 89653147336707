@import url('https://fonts.googleapis.com/css2?family=Alata&family=Baloo+Tamma+2:wght@400;600;700&display=swap');

* {
    padding: 0px;
    margin: 0px;
    outline: none;
}

html,body {
    width: 100vw;
    height: 100vh;
}


body {
    color:#000;
    background-repeat: no-repeat;
    background-size: cover;
    font-family: 'Alata', sans-serif;
}

h1,h2,h3,h3,h5 {
    font-family: 'Baloo Tamma 2', cursive;
}

main {
    transition: all 0.5s ease-in-out;

    .live{
        height: 100vh;
    }
}

a {
    text-decoration: none;
}


section,main {
    height: calc(100vh);
    overflow: auto;
}

.channels {
    width: 80px;
    position: fixed;
    right: 0px;
    top:0px;
    bottom:0px;
    background:rgba($color: #000000, $alpha: 0.4);

    .channel {
        width:60px;
        height:60px;
        overflow: hidden;
        border-radius:30px;
        color:#fff;
        background:#000;
        margin:10px;
        text-align:center;
        border:2px solid #fff;
        a {
            color:#fff;
            line-height:60px;
        }
    }
}

.liveframe{
    width:100%;
    height: 100%;
    border:0px;
}